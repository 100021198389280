<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  padding: 160px 0;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 10.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 89%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
      &-it {
        display: flex;
        justify-content: space-between;
        background: #070911;
        font-size: 14px;
        padding-bottom: 0px;
        &-item {
          width: 45.5%;
          padding: 20px;
          background: #0f1118;
        }
      }
    }
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> 瓯江风情旅游度假区 </p>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业简介
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              古堰画乡”具有深厚的历史文化底蕴，这里有建于公元505年的国家重点文物保护单位通济堰，而世界首创的拱形大坝和“水上立交桥”石函以及秀丽的瓯江风光、千年古樟树群、古镇、古村落构成的美景更是独树一帜，形象地表达了真山真水自然古朴的江南古镇。同时,这里还有省内外著名的“丽水巴比松画派”，建有丽水巴比松陈列馆、丽水油画院、古堰画乡展览馆、古堰画乡分校等，另有专业美术写生创作基地－“在水一方写生创作基地。这里有有丰富的特产，食品类的有茶叶、端午茶等；手工艺品有木作、陶瓷、青瓷、香囊、竹制品等；艺术品有香樟木版画、巴比松油画等。
              巴比松画派介绍：二十世纪八十年代末，一群生活在瓯江两岸的油画家们借鉴了法国巴比松画家的精神“画我画乡，走向自然”，来表达他们对家乡风土人情、自然风光发自内心的热爱，几近相同的创作风格和精神，使得丽水古堰画乡与坐落枫丹白露森林里的法国巴比松小镇有着异曲同工之处，而两个“巴比松”不仅是绘画手法的不谋而合，更是一种精神的传承。由此外界称丽水油画为“丽水巴比松画派”。详见古堰画乡公众号。
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            大赛背景
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <p>
            为顺应古堰画乡景区发展的战略目标，实现“既卖产品又卖文化”的需求，丰富古堰画乡文创产品体系，开发和设计一批彰显本土文化特色与生活气息、具有创新性、实用性、艺术性的产品，现面向社会各界征集文化创意设计作品。
          </p>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            征集内容
          </div>
        </div>
      </div>

      <div class="person-center-wrapper-selfInfo person-center-wrapper-selfInfo-it">
        <div class="person-center-wrapper-selfInfo-it-item">
          <p>
            （一）产品外包装
          </p>
          <p>
            茶叶类：红茶、绿茶、端午茶等；手工艺品：木作、陶瓷、青瓷、香囊、竹制品等；艺术品：香樟木版画、巴比松油画等。
          </p>
        </div>
        <div class="person-center-wrapper-selfInfo-it-item">
          <p>
            （二）文创及周边商品设计
          </p>
          <p>
            如何把丽水巴比松的油画元素运用到产品设计中去，给丽水巴比松画派赋予新的生命使之走进人们的生活。
          </p>
        </div>
      </div>

      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            创作要求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <p>
            （一）此次征集包括创意设计稿和实物产品两类；<br />
            （二）设计作品在追求图形、图案、结构、造型美感的同时，要着重围古堰画乡特色和丽水巴比松画派文化创意设计，注重文化与时尚元素的有机结合，彰显其独特魅力与 内涵，具有充分的文化属性；<br />
            （三）创意设计新颖独特，具有排他性的自主知识产权；<br />
            （四）注重创意和市场的对接，能够适应或者激发公众对于古堰画乡内在文化的探索欲和市场的现实要求，具有良好的实用价值、鉴赏价值、留念价值或者收藏价值；<br />
            （五）选材灵活，可包含但不限于布、纸、木、石、土、金属等各种材料；工艺精良，具备生产可行性，符合相关产品的技术质量标准和安全标准；<br />
            （六）设计作品需充分考虑作品的实用性与工艺性相结合，以及市场价值和批量化生产的可实现性；<br />
            （七）作品需要详细的文字描述、和效果图。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'enter',
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
  }
}
</script>
